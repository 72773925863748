#root {
	height: 100%;
	display: flex;
}

* {
	font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@font-face {
	font-family: 'Titillium';
	src: local('Titillium'), url(./assets/fonts/Titillium/Titillium-Regular.ttf) format('truetype');
	font-weight: normal;
}

@font-face {
	font-family: 'Titillium';
	src: local('Titillium'), url(./assets/fonts/Titillium/Titillium-Bold.ttf) format('truetype');
	font-weight: bold;
}
